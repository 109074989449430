export const LOCAL_STORAGE_KEY = 'customLocalStorageKey';
export const INITIAL_VALUES = {
  /*1**/ full_name: '',
  /*2**/ country: '',
  /*2a**/ tax_number: '',
  /*3**/ city: '',
  /*4**/ email: '',
  /*5*/ higher_education_institution: '',
  /*6**/ academic_position: 'No Academic position',
  /*7*/ administrative_position: '',
  /*8**/ knowledge_domain: '',
  /*9**/ academic_degree: '',
  /*10**/ pedagogical_experience: '',
  /*11**/ courses: '',
  /*12*/ committees_membership: '',
  /*13**/ pdp: '',
  /*14**/ conferences: '',
  /*15*/ pedagogical_experience_other: '',
  /*16*/ internship: '',
  /*17*/ professional_associations_membership: '',
  /*18*/ researcher_ID: `ORCID:___
Web of Science ResearcherID:___
SCOPUS AuthorID:___`,
  /*19*/ awards: '',
  /*20*/ motivation: '',
  /*21**/ portfolio_file: [],
  /*22**/ acceptedTerms: false,
};
export const LOCAL_STORAGE_SIGNIN_FORM_KEY = 'signInLocalStorageKey';
export const INITIAL_VALUES_SIGNIN_FORM = {
  /*1**/ full_name: '',
  /*1**/ country: '',
  /*1**/ tax_number: '',
  /*1**/ higher_education_institution: '',
  /*2**/ academic_position: '',
  /*3**/ administrative_position: '',
  /*4**/ knowledge_domain: '',
  /*5**/ academic_degree: '',
  /*6**/ pedagogical_experience: '',
};

export const pricePTdev = 'price_1KBLXmEBSL6EgXTCAVRYuBkA'; // dev
export const pricePT = 'price_1K8xlfEBSL6EgXTCHT5zNj9V';
export const priceNotPTdev = 'price_1K7NNDEBSL6EgXTCmhuvYYAX'; // dev
export const priceNotPT = 'price_1K7NFsEBSL6EgXTClBwSwxcO';

export const priceRegCard = 'price_1LCSSBEBSL6EgXTCht7d1B9Z';
export const priceRegCardMCM = 'price_1LCSYrEBSL6EgXTCvoOoF5Sk';
export const priceRegCardPlusIva = 'price_1LCOd4EBSL6EgXTCyOBYlmJm';
export const priceRegCardMCMPlusIva = 'price_1LCSUxEBSL6EgXTCurvXERTv';

// Forum products

// Forum discounts
export const couponToIPEER = 'ipeer';
export const couponToAffiliate = 'affiliation';
export const couponToAgency = 'agency';

// Agency price:
export const priceAgency = 'price_1QfmshEBSL6EgXTCzpzXRBRA'; // prod // 500 Euro
// export const priceAgency = 'price_1Qfmv4EBSL6EgXTCptsBRnST'; // dev // 500 Euro

//Price for Forum Delegate (29-30/05/2025) =  price_1QaQoNEBSL6EgXTCaLD0TBTn
export const priceForumDelegate = 'price_1QaQoNEBSL6EgXTCaLD0TBTn'; // prod // 500 Euro
export const priceForumDelegateAffiliateDiscount = 'price_1QeQmxEBSL6EgXTCDS0We69C'; // prod // 450 Euro
// export const priceForumDelegate = 'price_1Qa4ouEBSL6EgXTCnMTJVDjG'; // dev // 500 Euro
// export const priceForumDelegateAffiliateDiscount = 'price_1Qen33EBSL6EgXTCSjPUSdH1'; // dev // 450 Euro

// Price for Forum Delegate + EUR-ACE Preparation Workshop (29-31/05/2025) = price_1QaQqXEBSL6EgXTC9TV8ktDh
export const priceForumDelegateEurAcePrep = 'price_1QaQqXEBSL6EgXTC9TV8ktDh'; // 1200 Euro
export const priceForumDelegateEurAcePrepAffiliateDiscount = 'price_1QemQVEBSL6EgXTCvsIFE4us'; // 1080 Euro

// Price for Forum Delegate + EUR-ACE Experts Workshop (29-31/05/2025) = price_1QaQquEBSL6EgXTCuMQDYniv
export const priceForumDelegateEurAceExperts = 'price_1QaQquEBSL6EgXTCuMQDYniv'; // 2500 Euro
export const priceForumDelegateEurAceExpertsAffiliateDiscount = 'price_1QemRLEBSL6EgXTC7oJlxKz3'; // 2250 Euro

// Price for Open Day Delegate (29/05/2025) = price_1QaQrzEBSL6EgXTCi9RHts0e
export const priceOpenDayDelegate = 'price_1QaQrzEBSL6EgXTCi9RHts0e'; // 500 Euro
export const priceOpenDayDelegateAffiliateDiscount = 'price_1QemS9EBSL6EgXTCcmDYNvvP'; // 450 Euro

// Price for Open Day Delegate + EUR-ACE Preparation Workshop (29-31/05/2025) = price_1QaQsZEBSL6EgXTCwDfPDZDa
export const priceOpenDayDelegateEurAcePrep = 'price_1QaQsZEBSL6EgXTCwDfPDZDa'; // 1200 Euro
export const priceOpenDayDelegateEurAcePrepAffiliateDiscount = 'price_1QemScEBSL6EgXTCTv24QLvZ'; // 1080 Euro

// Price for Open Day Delegate + EUR-ACE Experts Workshop (29-31/05/2025) = price_1QaQswEBSL6EgXTCWDkr2SSN
export const priceOpenDayDelegateEurAceExperts = 'price_1QaQswEBSL6EgXTCWDkr2SSN'; // 2500 Euro
export const priceOpenDayDelegateEurAceExpertsAffiliateDiscount = 'price_1QemTWEBSL6EgXTCIJOTyEGR'; // 2250 Euro

// Price for EUR-ACE Preparation Workshop (27-28/05/2025) = price_1QaQtREBSL6EgXTCwJtSzOSD
export const priceEurAcePrep = 'price_1QaQtREBSL6EgXTCwJtSzOSD'; // 700 Euro
export const priceEurAcePrepAffiliateDiscount = 'price_1QemUEEBSL6EgXTCC8gSHSZS'; // 630 Euro

// Price for EUR-ACE Experts Workshop (27-30/05/2025) = price_1QaQtkEBSL6EgXTCkHuyfyiZ
export const priceEurAceExperts = 'price_1QaQtkEBSL6EgXTCkHuyfyiZ'; // 2000 Euro
export const priceEurAceExpertsAffiliateDiscount = 'price_1QemW6EBSL6EgXTCwCoERseE'; // 1800 Euro

export const priceMap = {
  'Forum Delegate (29-30/05/2025)': priceForumDelegate,
  'Forum Delegate + EUR-ACE Preparation Workshop (27-30/05/2025)': priceForumDelegateEurAcePrep,
  'Forum Delegate + EUR-ACE Experts Workshop (27-30/05/2025)': priceForumDelegateEurAceExperts,
  'Open Day Delegate (29/05/2025)': priceOpenDayDelegate,
  'Open Day Delegate + EUR-ACE Preparation Workshop (27-29/05/2025)':
    priceOpenDayDelegateEurAcePrep,
  'Open Day Delegate + EUR-ACE Experts Workshop (27-30/05/2025)': priceOpenDayDelegateEurAceExperts,
  'EUR-ACE Preparation Workshop (27-28/05/2025)': priceEurAcePrep,
  'EUR-ACE Experts Workshop (27-30/05/2025)': priceEurAceExperts,
};
export const AffiliateDiscountedPriceMap = {
  'Forum Delegate (29-30/05/2025)': priceForumDelegateAffiliateDiscount,
  'Forum Delegate + EUR-ACE Preparation Workshop (27-30/05/2025)':
    priceForumDelegateEurAcePrepAffiliateDiscount,
  'Forum Delegate + EUR-ACE Experts Workshop (27-30/05/2025)':
    priceForumDelegateEurAceExpertsAffiliateDiscount,
  'Open Day Delegate (29/05/2025)': priceOpenDayDelegateAffiliateDiscount,
  'Open Day Delegate + EUR-ACE Preparation Workshop (27-29/05/2025)':
    priceOpenDayDelegateEurAcePrepAffiliateDiscount,
  'Open Day Delegate + EUR-ACE Experts Workshop (27-30/05/2025)':
    priceOpenDayDelegateEurAceExpertsAffiliateDiscount,
  'EUR-ACE Preparation Workshop (27-28/05/2025)': priceEurAcePrepAffiliateDiscount,
  'EUR-ACE Experts Workshop (27-30/05/2025)': priceEurAceExpertsAffiliateDiscount,
};
